/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Helmet } from 'react-helmet';
import { colors } from '../styles/globals';
import DefaultLayout from '../containers/default';
import {
  ICMBlackIcon,
  PopulationHIcon,
  PopulationVIcon,
} from '../components/icons';
import {
  Section,
  SectionItem,
  SectionLi,
  SectionDescription,
} from '../components/section';
import {
  Banner,
  BannerItem,
  BannerTitle,
  BannerDescription,
  BannerLi,
} from '../components/banner';

import '../styles/index.css';

export default () => (
  <DefaultLayout>
    <Helmet>
      <title>all.health Solutions</title>
      <meta
        name="description"
        content="all.health Solutions"
      />
    </Helmet>
    <Banner reverse short backgroundColor={colors.blue}>
      <BannerItem left wide>
        <BannerTitle color={colors.white}>
          manage your entire population with one platform
        </BannerTitle>
        <BannerDescription color={colors.white}>
          Our Integrated Continuous Management (ICM™) solution is currently offered through:
        </BannerDescription>
        <BannerDescription color={colors.white} as="div" isList>
          <ol>
            <BannerLi>providers to help manage their patients' health, covering everything from wellness to chronic conditions</BannerLi>
            <BannerLi>employers for their employees</BannerLi>
          </ol>
        </BannerDescription>
      </BannerItem>
      <BannerItem right isIcon>
        <PopulationHIcon />
        <PopulationVIcon />
      </BannerItem>
    </Banner>
    <Section high="true">
      <SectionItem left isIcon bigIcon backgroundColor={colors.grey1}>
        <ICMBlackIcon />
      </SectionItem>
      <SectionItem right>
        <SectionDescription>
          By integrating the data and functionality of many tools into one
          connected platform, all.health™ not only reduces the administrative
          burden for providers who use it, but also rationalizes the number of
          systems and devices needed to monitor care.
        </SectionDescription>
        <SectionDescription>
          The platform is data agnostic, meaning data from other devices — including
          continuous glucose monitors, weight scales, or other wearables — can be
          integrated into the system to supplement the information provided by our band.
        </SectionDescription>
        <SectionDescription>
          As our customer, you will receive:
        </SectionDescription>
        <SectionDescription isList as="div">
          <ul>
            <SectionLi>Our Clinical Platform</SectionLi>
            <SectionLi>Our Band 01 and smartphone app for your patients and users</SectionLi>
            <SectionLi>Third-party connected products for the patients you manage with chronic conditions</SectionLi>
            <SectionLi>Datology™ as a Service</SectionLi>
          </ul>
        </SectionDescription>
      </SectionItem>
    </Section>
  </DefaultLayout>
);
